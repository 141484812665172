import React from 'react';

const Banners = () => {
    return (
        <div>
            Banners
        </div>
    );
};

export default Banners;