import React from 'react';

const Buy = () => {
    return (
        <div>
            Buy
        </div>
    );
};

export default Buy;