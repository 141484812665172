import { Link } from "react-router-dom";
import "./Home.css";
const Home = () => {
    return (
        <div className="_bg-1 mt-[70px] bg-no-repeat bg-cover flex bg-left h-[calc(100vh-70px)]">
            <div className="w-[1140px] max-w-[90%] mx-auto flex pt-20 justify-center">
                <div className="w-full">
                    <h2 className="text-4xl lg:text-5xl font-m-normal font-semibold text-black/80">The best bitcoin cloud mining and passive income site</h2>
                    <p className="font-m-tello">
                        We make industrial bitcoin mining accessible for everyone. Freemining.co is everything you need for bitcoin cloud mining today. Get 2500 Satoshis daily in two minutes.
                    </p>
                    <Link className="inline-block my-4 text-blue-400" to='/dash'>Dashboard</Link>
                </div>
                <div className="w-full hidden md:block">
                    <img src="/img/smartprofits8.png" alt="" className="z-[10] anim1" />
                </div>
            </div>
        </div>
    );
};

export default Home;
