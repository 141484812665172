import React from 'react';

const Affilates = () => {
    return (
        <div>
            Affilates
        </div>
    );
};

export default Affilates;