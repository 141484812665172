import { BsCurrencyBitcoin, BsFillCalculatorFill } from 'react-icons/bs';
import { AiOutlinePieChart } from 'react-icons/ai';
import { HiInformationCircle, HiOutlineUsers, HiUserCircle } from 'react-icons/hi';
import { GiEmptyHourglass } from 'react-icons/gi';
import { BiLink } from 'react-icons/bi';
import { RxExternalLink } from 'react-icons/rx';
import { MdOutlineAccountTree, MdOutlineFileCopy } from 'react-icons/md';
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown, FaRegChartBar, FaBolt, FaBitcoin } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {

    const [plans] = useState([
        {
            name: 'Basic',
            min: 0.002,
            days: 365,
            perc: 3,
            daily: 0.00006,
            spins: 5
        },
        {
            name: 'Starter',
            min: 0.015,
            days: 365,
            perc: 3.333,
            daily: 0.0005,
            spins: 15
        },
        {
            name: 'Standard',
            min: 0.05,
            days: 365,
            perc: 3.5,
            daily: 0.00175,
            spins: 35
        },
        {
            name: 'Enterprise',
            min: 0.15,
            days: 365,
            perc: 4.5,
            daily: 0.00675,
            spins: 75
        },
        {
            name: 'Professional',
            min: 0.35,
            days: 365,
            perc: 5,
            daily: 0.0175,
            spins: 125
        }
    ]);


    const [showAmt, setShowAmt] = useState({ perc: plans[0].daily, th: (plans[0].daily * 2083333.33).toFixed(2), daily: plans[0].daily + ' BTC' });
    const [input, setInput] = useState(plans[0].min);
    const [allowedChars] = useState('0123456789.');

    useEffect(() => {
        const newI = Number(input).toFixed(8);
        const nNewU = Number(newI);
        if (nNewU < 0) return setShowAmt({ perc: plans[0].perc, th: (plans[0].daily * 2083333.33).toFixed(2), daily: '0.00000000 BTC' });
        if (nNewU > 9999) return setShowAmt({ perc: plans[0].perc, th: (plans[0].daily * 2083333.33).toFixed(2), daily: 'Invalid amount' });
        let plan;
        for (let i = 0, len = plans.length; i < len; i++) {
            if (plans[i].min > nNewU) break;
            plan = plans[i];
        }
        if (plan === undefined) return setShowAmt({ perc: plans[0].perc, th: (plans[0].daily * 2083333.33).toFixed(2), daily: `Minimum ${plans[0].min} BTC` });
        setShowAmt({ perc: plan.perc, th: (plan.daily * 2083333.33).toFixed(2), daily: (nNewU * plan.perc * 0.01).toFixed(8) });
    }, [input, plans]);
    const ChangeCalcAmt = e => {
        const inp = e.target.value;
        let newInp = inp.split("").filter(v => allowedChars.includes(v)).join("");
        let tNp = newInp;
        if ((newInp.match(/\./g) || []).length > 1) return setInput(plans[0].min);
        setInput(tNp);
    }

    const [reward, setReward] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setReward((Number(reward) + 6.94444444e-11).toFixed(10))
        }, 50);
    }, [reward]);

    return (
        <div className='w-[1140px] max-w-[90%] mx-auto'>
            <div className='mt-12 flex flex-col lg:flex-row gap-5'>
                <div className='text-white acc-bal-div p-5 md:p-8 rounded flex flex-col gap-3 relative h-216px] w-full lg:max-w-[350px] overflow-hidden'>
                    <img src="/images/card-right.png" alt="" className='absolute top-0 right-0' />
                    <div className='acc-bal text-white relative flex justify-between'>
                        <div className="">
                            <p className='text-[#74fffa] text-[12px] font-medium tracking-widest'>MINING REWARD</p>
                            <p className='text-2xl font-medium'>{reward} <small className='text-sm font-normal'>BTC</small></p>
                            <div className='flex items-center gap-3 mt-1'>
                                <p>$0.00 USD</p>
                                <button className='shadow-lg rounded tracking-wider text-green-800 bg-green-500 hover:bg-green-600 hover:shadow-none px-2 duration-300 text-[14px]'>CLAIM</button>
                            </div>
                        </div>
                        <div className="flex fan relative">
                            <img src="/images/fan1.svg" alt="" className='absolute -top-[2px]' />
                        </div>
                    </div>
                    <div className='flex items-end justify-between'>
                        <div>
                            <p className='text-[#74fffa] text-[12px] font-medium tracking-widest'>YOUR HASHPOWER</p>
                            <p className='text-[20px] font-medium'>0.00 TH/s</p>
                            <p className='text-xs'>Active Hashpower</p>
                        </div>
                        <div>
                            <p className='text-[20px] font-medium'>0.00000000 BTC</p>
                            <p className='text-xs'>Earnings per day</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-center gap-5 w-full'>
                    <div className='flex flex-col lg:flex-row bg-white px-5 py-3 justify-between gap-5'>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-1'><BsCurrencyBitcoin className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Current Investment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>0.00000000 <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-2'><FaRegArrowAltCircleUp className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Last Investment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>0.00000000 <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-5'><AiOutlinePieChart className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Total Investment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>0.00000000 <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row bg-white px-5 py-3 justify-between gap-5'>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-3'><FaRegArrowAltCircleDown className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Last Payment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>0.00000000 <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-4'><GiEmptyHourglass className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Pending Payment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>0.00000000 <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-6'><FaRegChartBar className='text-[#696460] text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Total Payment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>0.00000000 <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-10 flex flex-col lg:flex-row w-full gap-5 lg:gap-10'>
                <div className='rounded-lg border border-[#bcc5d6] lg:w-2/3 acc-bal-2 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <MdOutlineAccountTree className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Account Activity</p>
                    </div>
                    <div className='p-[10px] px-5'>
                        You have no activiy
                    </div>
                </div>
                <div className='rounded-lg border border-[#bcc5d6] lg:w-1/3 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <BsFillCalculatorFill className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Miner Calculator</p>
                    </div>
                    <div className="m-5 lg:m-8 relative">
                        <input type="text" inputMode='decimal'
                            className='w-full border border-[#d2dde9] rounded-[4px] h-10 duration-300 flex pl-4 font-medium text-[18px] pr-[60px] outline-none text-[#495463b3]'
                            style={{ boxShadow: '0 3px 14px 0px rgb(48 70 175 / 25%)' }} onChange={ChangeCalcAmt} onKeyUp={ChangeCalcAmt} value={input} />
                        <span className='border border-[#d2dde9] absolute top-[9px] text-[13px] right-0 text-[#758698] font-medium px-3 border-y-0'>BTC</span>
                        <div className='flex items-center mt-5'>
                            <FaBolt className='text-white mr-3 bg-[#ed9633] rounded-full p-1 text-2xl' />
                            <p className='text-[#758698] text-[12px] font-medium tracking-widest'>DAILY PROFITS</p>
                        </div>
                        <div className='flex justify-evenly mt-3'>
                            <div className='flex flex-col items-center'>
                                <div className='border border-[#a4c5f0] text-[14px] font-medium bg-[#e6effb] rounded px-5 py-2 text-[#495463]'>{showAmt.perc}% Daily</div>
                                <p className='text-[11px] text-gray-500'>Daily Percent</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <div className='border border-[#a4c5f0] text-[14px] font-medium bg-[#e6effb] rounded px-5 py-2 text-[#495463]'>{showAmt.th} TH/s</div>
                                <p className='text-[11px] text-gray-500'>Mining Hashpower</p>
                            </div>
                        </div>
                        <hr className='my-3' />
                        <p className="text-center text-[20px] text-[#495463] font-medium">{showAmt.daily}</p>
                        <p className='text-[11px] text-gray-500 text-center mb-2'>Daily Income</p>

                        <div className='flex items-center text-[12px] text-[#758698] gap-2 mb-7'>
                            <HiInformationCircle className='text-lg mt-[2px]' />
                            <p>Amount calculated based current btc dificult, price</p>
                        </div>
                        <Link to='/dash/new' className='text-white rounded-md bg-[#2c80ff] w-40 py-3 block text-center font-medium hover:bg-[#005fee] duration-300 mx-auto'>
                            Quick Deposit
                        </Link>
                    </div>
                </div>
            </div>
            <div className='my-10 flex flex-col lg:flex-row w-full gap-5 lg:gap-10'>
                <div className='rounded-lg lg:w-1/3 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <HiOutlineUsers className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Referral Stats</p>
                    </div>
                    <div className="m-5 lg:m-8 relative">
                        <div className='flex justify-between items-center mb-3'>
                            <p className='font-bold text-[#253992]'>Referral URL</p>
                            <Link className='text-[#2c80ff] tracking-widest font-bold text-[12px] duration-300 hover:text-[#0063f8]' to='/dash/affiliates'>GO TO AFFILIATES</Link>
                        </div>
                        <input type="text" inputMode='decimal'
                            className='w-full border border-[#d2dde9] rounded-[4px] h-12 duration-300 flex pr-[50px] pl-[40px] outline-none text-[#495463]'
                            value={`https://firemine.com/r/Rg53Fd5He3`} readOnly />
                        <span className='absolute top-[42px] text-[13px] right-[6px] group bg-[#e9eff9] font-medium p-2 hover:bg-[#2c80ff] duration-300 cursor-pointer rounded'>
                            <MdOutlineFileCopy className='text-[#758698] group-hover:text-white text-xl' />
                        </span>
                        <span className='absolute top-[42px] text-[13px] left-[6px] font-medium p-2'>
                            <BiLink className='text-[#495463] group-hover:text-white text-xl' />
                        </span>
                        <p className='text-[#253992] my-4 text-md font-medium'>Current Stats</p>

                        <p className='tracking-widest text-[#758698] text-[12px] font-medium'>AFFILIATE COMMISIONS</p>
                        <div className='flex items-center gap-2'><FaBitcoin className='text-2xl text-[#ed9633]' /> <span className='text-[#495463] font-medium'>0.00000000 BTC</span></div>
                        <hr className="mt-3 mb-5" />
                        <p className='tracking-widest text-[#758698] text-[12px] font-medium'>LAST REFERRED USER</p>
                        <div className='flex items-center gap-2'><HiUserCircle className='text-2xl text-[#237cdb]' />
                            <span className='text-[#495463] font-medium'>{false || `None`}</span>
                        </div>
                        <hr className="mt-3 mb-5" />
                        <p className='tracking-widest text-[#758698] text-[12px] font-medium'>ALL REFFERALS</p>
                        <div className='text-[#495463] font-medium text-2xl'>0</div>

                    </div>
                </div>
                <div className='rounded-lg lg:w-2/3 bg-white p-5 pt-4'>
                    <div className='rounded-lg rounded-b-none flex items-center gap-3'>
                        <RxExternalLink className='text-[#253992] text-xl' /> <p className='font-medium text-[#253992] text-xl'>Get new referrals</p>
                    </div>
                    <p className='py-5 -mt-5 text-[14px]'>share your link with your friends and get 5% free bonus</p>
                    <div className='overflow-x-auto'>
                        <table className='w-full'>
                            <thead>
                                <tr>
                                    <td className='font-medium text-gray-600'>Date</td>
                                    <td className='font-medium text-gray-600'>Wallet</td>
                                    <td className='font-medium text-gray-600'>Invest</td>
                                    <td className='font-medium text-gray-600'>Bonus</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                    <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                    <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                </tr>
                                <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                    <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                    <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                </tr>
                                <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                    <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                    <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;