import { createContext, createRef, useEffect, useState } from "react";
import Home from "./Components/Home/Home";
import Header from "./Components/Shared/Header/Header";
import ReCAPTCHA from 'react-google-recaptcha';
import { Route, Routes } from "react-router-dom";
import Dash from "./Components/Dash/Dash";
import Buy from "./Components/Dash/Pages/Buy";
import Withdraw from "./Components/Dash/Pages/Withdraw";
import Affilates from "./Components/Dash/Pages/Affilates";
import Settings from "./Components/Dash/Pages/Settings";
import Banners from "./Components/Dash/Pages/Banners";
import Dashboard from "./Components/Dash/Pages/Dashboard";

export const MyContext = createContext();

function App() {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [userLoading, setUserLoading] = useState(false);
    const [menuExpanded, setMenuExpanded] = useState(false);
    const recaptchaRef = createRef();

    const [hudai, setHudai] = useState(localStorage.getItem('okay'));
    const [searchParams] = useState(window.location.search);
    useEffect(() => {
        if (!hudai && searchParams.substring(0, 5) === '?pass') {
            setHudai('okay');
            localStorage.setItem('okay', 'okay');
        }
    }, [hudai, searchParams]);
    if (hudai !== 'okay' || userLoading) return;

    return (
        <MyContext.Provider value={{ loading, setLoading, user, setUser, setUserLoading, recaptchaRef }}>
            <ReCAPTCHA
                size='invisible'
                ref={recaptchaRef}
                sitekey='6LemfPojAAAAACo4eknsynmuXz4370x_xiE0rYy6'
                className='z-[9]'
            />
            <Header values={{ menuExpanded, setMenuExpanded }} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dash" element={<Dash />}>
                    <Route index element={<Dashboard />} />
                    <Route path="new" element={<Buy />} />
                    <Route path="withdraw" element={<Withdraw />} />
                    <Route path="affiliates" element={<Affilates />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="banners" element={<Banners />} />
                </Route>
            </Routes>
        </MyContext.Provider>
    );
}

export default App;
